.navOpen {
  transform: none;
  opacity: 1;
  transition: transform 600ms ease-out, opacity 600ms ease-out;
}

.navClosed {
  transform-origin: 0% 0%;
  opacity: 0;
  transform: translateX(100%);
  transition: transform 600ms ease-out, opacity 600ms ease-out;
}
